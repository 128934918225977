var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _vm.url
      ? _c("div", { staticClass: "block" }, [
          _c("iframe", {
            attrs: {
              src: _vm.url,
              width: "100%",
              height: "768px",
              frameborder: "0",
              scrolling: "auto",
            },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }